import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('@/views/Catalog.vue')
  },
  {
    path: '/specific_reports',
    name: 'specificReports',
    component: () => import('@/views/SpecificReports.vue')
  },
  {
    path: '/catalog_result',
    name: 'catalogResult',
    component: () => import('@/views/CatalogResult.vue'),
    props: route => route.query
  },
  {
    path: '/focal_mechanism/:flag',
    name: 'focalMechanism',
    component: () => import('@/views/FocalMechanism.vue'),
    props: true
  },
  {
    path: '/station',
    name: 'station',
    component: () => import('@/views/Station.vue')
  },
  {
    path: '/shakemaps',
    name: 'shakemaps',
    component: () => import('@/views/Shakemaps.vue')
  },
  {
    path: '/project/:name?',
    name: 'project',
    component: () => import('@/views/Projects.vue'),
    props: true
  },
  {
    path: '/links',
    name: 'links',
    component: () => import('@/views/Links.vue')
  },
  {
    path: '/event/:eventid',
    name: 'event',
    component: () => import('@/views/EventPage.vue'),
    props: true
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
