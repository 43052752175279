export default {
  fr: {
    home: {
      eventlist1: "Événements dans la zone d'étude (7 derniers jours)",
      eventlist2: 'Temps en heure locale (Europe/Paris)',
      infoTitle: 'Informations complémentaires',
      info1_1: 'Les données analysées en temps réel (voir ',
      info1_2: ') sont les données de stations sismologiques installées par Géoazur, mais aussi transmises depuis Grenoble (ISTerre), Toulouse (OMP), et les pays limitrophes (Italie, Suisse, Monaco et Espagne).',
      info2_1: "Le partage des données en temps réel est négocié dans le cadre d'accords de collaboration inter-frontalière établis au cours du projet ",
      info2_2: ' (voir ',
      info2_3: ').',
      info3_1: 'Le logiciel de détection automatique est ',
      info3_2: ' développé à ',
      info3_3: ', Potsdam et par la société ',
      info3_4: '.',
      info4_1: 'Des outils complémentaires ont été mis au point à Géoazur par B. Delouis pour la détermination automatique de la ',
      info4_2: 'magnitude de moment',
      info4_3: ' (MWNEAR) et le ',
      info4_4: 'mécanisme au foyer',
      info4_5: ' (FMNEAR) des séismes les plus importants.'
    },
    nav: {
      home: 'Accueil',
      scientificData: 'Données scientifiques',
      catalog: 'Catalogue',
      scientificalResults: 'Résultats scientifiques',
      fdsnws: 'Webservices FDSN',
      station: 'Stations',
      projects: 'Projets',
      info: 'Informations',
      risk: 'EN SAVOIR PLUS',
      riskDoc: 'risq-sismiq_coteAzur.pdf',
      links: 'Liens',
      reports: 'Rapports spécifiques'
    },
    app: {
      geolocationTitle: 'Géolocalisation',
      geolocationContent1: "Activer la géolocalisation permet de bénéficier d'outils supplémentaires :",
      geolocationContent2: 'lister les événements en fonction de votre position',
      geolocationContent3: 'lister les stations en fonction de votre position',
      geolocationContent4: "Aucune donnée personnelle n'est communiquée sur le serveur.",
      yes: 'Oui',
      no: 'Non',
      days: 'j',
      hours: 'h',
      minutes: 'm',
      seconds: 's'
    },
    event: {
      text1: 'Type',
      text2: 'Magnitude',
      text3: 'Date et heure',
      text4: 'Latitude',
      text5: 'Longitude',
      text6: 'Profondeur',
      text7: 'Carte',
      text8: 'Bulletin',
      text9: 'Stats',
      text10: 'Sismogrammes',
      text11: 'Les données sismologiques distribuées ici sont au format SAC.',
      text12: 'Une authentification est requise pour leur téléchargement.',
      text13: 'Télécharger les données',
      text14: 'Témoignages',
      text15: 'Vous avez ressenti ce séisme ?',
      text16: 'Vous pouvez témoigner en remplissant le ',
      text17: 'formulaire du BCSF',
      text18: ', cela ne prendra que 3 minutes !'
    },
    reports: {
      text1_1: 'Le séisme de magnitude 4.1 du 18 mars 2025 au nord de Nice, commune de Coaraze, et la séquence sismique qui a suivi.',
      text1_2: 'Rapport scientifique et technique :',
      text1_3: 'Vidéo Epos-France sur le même sujet, plus grand public :',
      text2_1: 'Le séisme de magnitude 4.3 du 14 septembre 2024 au large de Cannes - Fréjus.',
      text2_2: 'Rapport scientifique et technique :'
    },
    catalog: {
      warn1: 'Les champs sont réglés comme votre dernière requête. Vous pouvez revenir à la requête par défaut en cliquant',
      warn2: 'ici',
      form1: 'Dates',
      form2: 'Derniers jours',
      form3: 'Début',
      form4: 'Fin',
      form5: 'Zone géographique',
      form6: 'min',
      form7: 'max',
      form8: 'Latitude',
      form9: 'Longitude',
      form10: "Type(s) d'événement",
      form11: 'tous les types',
      form12: 'séisme',
      form13: 'tir de carrière',
      form14: 'explosion',
      form15: 'indéfini',
      form16: 'Profondeur',
      form17: 'Magnitude',
      form18: 'Format de sortie',
      form19: 'Envoyer',
      form20: "Accéder à l'événement",
      form21: 'Y Aller',
      resultNav1: 'Liste',
      resultNav2: 'Carte',
      resultNav3: 'Stats',
      resultList1: 'Résultats :',
      resultList2: 'événements',
      result0: 'Paramètres de recherche',
      result1: 'minimale',
      result2: 'maximale'
    },
    map: {
      legend0: 'Légende',
      legend1: 'Magnitude',
      legend2: "Évaluation de l'événement",
      legend3_1: 'automatique',
      legend3_2: 'Événement détecté automatiquement, pas encore confirmé par le service sismologique.',
      legend4_1: 'séisme',
      legend4_2: "L'événement est un séisme, confirmé par le service sismologique.",
      legend5_1: 'artificiel',
      legend5_2: "Événement d'origine anthropique, confirmé par le service sismologique.",
      legend6_1: 'indéfini',
      legend7: 'Voir plus',
      legend8: 'Disponibilité de calculs supplémentaires',
      legend9: 'Mécanisme au foyer',
      legend10: 'Shakemap',
      legend11: 'Autres symboles',
      legend12: 'Station sismologique',
      disclaimer: 'La détection est automatique, et pas nécessairement validée par un sismologue.'
    },
    station: {
      list1: 'Latence',
      list2: 'stations avec dayplot',
      list3: 'latence',
      panel1: 'Latitude',
      panel2: 'Longitude',
      panel3: 'Altitude',
      panel4: 'Carte',
      panel5: 'Derniers séismes',
      panel6: "Date d'installation",
      map1: 'Légende',
      map2: 'Latence'
    },
    links: {
      text1: 'Liens',
      text2: 'Informations',
      text3: 'Ce site a été construit dans le cadre des projets RISE et CASSAT du programme ALCOTRA 2007-2013 (financé par les fonds FEDER, le CG06, la DREAL PACA et le CNRS), et du projet RISVAL du programme Alcotra 2014-2020 (financé par les fonds FEDER et le CNRS).',
      text4_1: 'Il est maintenu dans le cadre du ',
      text4_2: "service d'observation sismologique de Géoazur",
      text4_3: " au sein de l'Observatoire de la Côte d'Azur.",
      text5: "Le réseau sismologique sur lequel il s'appuie est largement composé d'instruments déployés en Paca pour le compte du réseau sismologique national de recherche (projet RESIF - CNRS/INSU, OCA, IPGP, Universités, CEA, BRGM, ...).",
      text6: 'Le financement est assuré par le projet RESIF avec une participation régulière du CG06 et occasionnelle des projets ALCOTRA.',
      text7: 'Quelques sites de référence',
      text8: 'Vous trouverez ci-dessous un ensemble de site intéressants pour de plus amples informations.',
      text9: 'Sites régionaux',
      text10_1: 'DIPTERIS, partenaire',
      text10_2: "Enregistrement et caractérisation des séismes d'Italie du Nord-Ouest.",
      text11_1: 'ICGC',
      text11_2: 'Institut Cartographique et Géologique de Catalogne.',
      text12_1: 'SED',
      text12_2: 'Centre national en charge de la sismicité en Suisse, Alpes du Nord.',
      text13_1: 'Sismalp',
      text13_2: 'Enregistrement et analyse de la sismicité des Alpes (françaises).',
      text14_1: 'OMP',
      text14_2: 'Observatoire Midi-Pyrénées',
      text15: 'Sites nationaux et internationaux',
      text16_1: 'Observatoire EduMed',
      text16_2: 'Suivi de la sismicité par et pour les collègs/lycée.',
      text16_3: 'Présente aussi de nombreuses activités pédagogiques à partir des enregistrements sismologiques.',
      text17_1: 'LDG',
      text17_2: "Bulletin d'alerte officiel pour la France.",
      text18_1: 'CSEM-EMSC, Centre Sismologique Euro-Méditerranéen',
      text18_2: 'Focalisé sur le bassin méditérranéen, ce site offre aussi le suivi de la sismicité mondiale.',
      text19_1: 'USGS (en anglais)',
      text19_2: 'Suivi de la sismicitié mondiale.',
      text20: 'Sites complémentaires',
      text21_1: 'Azurséisme',
      text21_2: "Site d'information sur la sismicité de la région.",
      text22_1: 'BCSF',
      text22_2: 'Bureau Central Sismologique Français'
    },
    project: {
      title: 'Projets',
      headline: 'Trois projets simples du programme Alcotra (Alpes Latines Coopération Transfrontalière France-Italie) (2007-2013 et 2014-2020) ont permis le développement de ce site.',
      rise_summary_1: 'Le projet ',
      rise_summary_2: " (2013-2015) soutien le développement d'enregistrement des mouvements forts du sol pour assurer une bonne estimation des magnitudes en cas de séisme fort dans la région. Par ailleurs, il s'appuie sur la détection automatique des caractéristiques des séismes et des connaissances dans la région pour apporter une aide à l'évaluation rapide des mouvements forts du sol ceci en vue d'améliorer la gestion de crise.",
      rise_title: "Réseaux Intégrés de Surveillance sismologiques et d'Échange (RISE)",
      rise_headline: 'Projet Simple dans le cadre du ALCOTRA Alpes Latines Coopération Transfrontalière France-Italie (2007-2013)',
      rise_table1_1: "la Région du Val d'Aoste",
      rise_table1_2: 'Porteur scientifique : LGIT (Grenoble)',
      rise_table2_1: "Italie : Région du Val d'Aoste, Région du Piedmont",
      rise_table2_2: 'France : LGIT, Géoazur',
      rise_table2_3: 'Suisse : ETH Zürich',
      rise_table2_4: 'Monaco',
      rise_table3: 'février 2009 - mars 2012',
      rise_section1_1: 'Objectifs',
      rise_section1_2: 'Nous nous intéressons dans ce projet à la gestion de crises sismiques Alpines transfrontalières. Les enjeux sont à la fois scientifiques et sociétaux, intéressant les collectivités régionales et les observatoires sismologiques.',
      rise_section1_3: "La carte de sismicité des Alpes occidentales montre que la distribution des séismes est partagée entre la France, l'Italie et la Suisse. Aussi, une gestion efficace des crises sismiques nécessite une intégration des réseaux de surveillance sismique et des réseaux d'échange d'informations. Il faut être capable (i) de partager en temps réel les données sismiques acquises de part et d'autres des frontières, (ii) d'échanger rapidement les analyses réalisées par les observatoires, (iii) d'échanger rapidement des informations diverses mais nécessaires à une intervention post-sismique (nature du sol, structure des agglomérations frontalières, cartes), et enfin, (iv) coordonner les interventions post-sismique pour le déploiement de réseaux temporaires en extérieur et en bâtiment.",
      rise_section1_4: "Les points (i) et (ii) engagent la qualité du diagnostic scientifique et la fiabilité du message délivré aux autorités, les points (iii) et (iv) permettent d'améliorer la diffusion d'informations sur le mouvement du sol et la réponse du bâti.",
      rise_section2_1: 'Participation du laboratoire Géoazur',
      rise_section2_2: "Elle a consisté en l'échange des données sismologiques et la mise en place d'outils de traitement en temps réel des données. Ainsi, Bertrand de Saint-Jean, Jérôme Salichon, Perrine Deshayes, Anne Deschamps, et Bertrand Delouis ont contribué significativement aux résultats mis en ligne.",
      rise_section3_1: 'Etat des lieux',
      rise_section3_2: "Les différents observatoires en charge de la surveillance sismique Alpine occidentale sont inégalement équipés. On constate un important retard technologique de l'équipement français, qui rend impossible l'intégration et la réalisation des points (i) et (ii). Les contacts existent entre les différents partenaires du projet, mais nécessitent d'être formalisés au vu des objectifs (iii) et (iv).",
      rise_section3_3: 'Nous proposons de réaliser deux volets dans le cadre du projet RISE :',
      rise_section3_4: "Intégration matérielle de l'observation sismique temps réel. Il s'agit de mettre l'équipement au niveau du standard adopté par nos collègues suisses et italiens. L'homogénéisation du matériel, des protocoles d'échanges est une étape indispensable pour permettre l'échange de données aux nouveaux standards de qualité et de rapidité : liaisons ADSL, flux SeedLink, serveurs régionaux ouverts, protocole d'échange.",
      rise_section3_5: "Financement d'équipement",
      rise_section3_6: 'Financement de réunion de coordination',
      rise_section3_7: "Coordination post-sismique permettant de déployer rapidement et aux bons endroits le matériel et les équipes de sismologues et d'ingénieurs. Constitution et identification d'un groupe intervention en post-sismique pour la région des Alpes du Nord et du Sud. Collecte et échange de données SIG, géotechniques. Définition des prérogatives du groupe en matière d'évaluation post-sismique. Mise en place d'un protocole d'intervention et d'échange d'information entre les réseaux de surveillance lors de crises sismiques.",
      rise_section3_8: 'Financement de réunion de coordination',
      rise_section3_9: "Constitution d'un fond de documents SIG orientés intervention sismique",
      rise_section4_1: 'Partenaires du projet',
      rise_section4_2: 'Ce projet a été soutenu par divers partenaires',
      cassat_summary_1: 'Le projet ',
      cassat_summary_2: " (2013-2015) soutien le développement d'enregistrement des mouvements forts du sol pour assurer une bonne estimation des magnitudes en cas de séisme fort dans la région. Par ailleurs, il s'appuie sur la détection automatique des caractéristiques des séismes et des connaissances dans la région pour apporter une aide à l'évaluation rapide des mouvements forts du sol ceci en vue d'améliorer la gestion de crise.",
      cassat_title: 'Coordination et Analyse de la Surveillance Sismique Alpine Transfrontalière (CASSAT)',
      cassat_headline: 'Projet Simple dans le cadre du ALCOTRA Alpes Latines Coopération Transfrontalière France-Italie (2013-2015)',
      cassat_table1: "la Région du Val d'Aoste",
      cassat_table2_1: "Italie : Région du Val d'Aoste, Région du Piedmont",
      cassat_table2_2: 'France : ISTerre (UJF), Géoazur (CNRS), CETE Méditerranée (CEREMA)',
      cassat_table3: 'janvier 2013 - mars 2015',
      cassat_section1_1: 'Etat des lieux',
      cassat_section1_2: "Le projet RISE a permis d'homogénéiser sur le territoire concerné l'observation par des stations sismologiques dotées de capteurs vélocimétriques dédiés à la détection des séismes les plus faibles. Il a permis aussi d'établir les contacts entre les partenaires intéressés dans la surveillance sismique sur cette zone frontalière. La durée de 3 ans n'avait pas permis de pérennisé par une convention les échanges de données entre centres français et italiens.",
      cassat_section1_3: "Par ailleurs, le contrôle des mouvements du sol en cas de séisme fort n'est possible dans les zones épicentrales (qui sont les plus impactées) qu'avec des enregistrements accélérométriques, moins sensibles que les enregistrements vélocimétriques. Les différents observatoires en charge de la surveillance sismique Alpine occidentale sont inégalement équipés en accéléromètres adaptés à une utilisation en temps réel de l'information. On constate en particulier un retard important sur le territoire du Val d'Aoste et dans la région niçoise.",
      cassat_section2_1: 'Objectifs',
      cassat_section2_2: 'Les objectifs du projet sont :',
      cassat_section2_3: "de consolider les acquis sur le partage des données en temps réel entre les partenaires pour fiabiliser les outils de localisation des séismes de la région frontalière utilisés pour l'alerte et l'information tant auprès des autorités qu'auprès de la société civile après un séisme ressenti.",
      cassat_section2_4: "d'améliorer la qualité des détections, de la localisation et de l'évaluation de la magnitude d'événements par les procédures automatiques.",
      cassat_section2_5: "d'ajouter aux outils existants, la production d'une \"shakemap\", carte de mouvements forts associés à chaque séisme de magnitude supérieure à 3.5 de la zone. Cette carte sera construite à partir d'outils existants, mais les données régionales disponibles caractérisant les effets de site et l'atténuation des ondes avec la distance, seront intégrés dans cette construction. Le résultat sera mis à disposition des autorités.",
      cassat_section2_6: 'Les enjeux sont à la fois scientifiques et sociétaux, intéressant les collectivités régionales et les observatoires sismologiques en charge de produire des catalogues de sismicité.',
      cassat_section2_7: "Les points (1) et (2) engagent la qualité du diagnostic scientifique et la fiabilité du message délivré aux autorités, le point (3) permettra d'améliorer la diffusion d'informations sur le mouvement du sol.",
      cassat_section3_1: 'Participation du laboratoire Géoazur',
      cassat_section3_2: "Géoazur contribue par toute son équipe du pôle observatoire (B. Delouis, D. Brunel, J. Chèze, X. Martin, C. Maron, F. Peix, L. Stehly) à la maintenance de l'instrumentation sur le terrain et la jouvence des stations accélérométriques, à la gestion et au contrôle de qualité des données transmises, à l'implémentation et la paramétrisation des outils de détection et localisation de la sismicité.",
      cassat_section3_3: "S. Zerath et L. Honoré ont ponctuellement participé à la collecte et la validation d'informations pour paramétrer le logiciel de \"shakemap\".",
      cassat_section3_4: "David Ambrois assure l'implémentation du logiciel et les tests nécessaires sur les différents paramètres pour assurer la fiabilité et une certaine pérennité à l'outil. Il est encadré pour les développements nécessaires par les sismologues de Géoazur (A. Deschamps, F. Courboulex, B. Delouis) et travaille en collaboration avec les partenaires (CETE Méditerranée et ISTerre et plus épisodiquement les partenaires italiens).",
      cassat_section3_5: 'Le suivi administratif du projet est assuré par M. Aufeuvre.',
      cassat_section4: 'Partenaires du projet',
      risval_summary: "Le projet RISVAL (2017-2020), a contribué à la maintenance et l'amélioration du réseau sismologique essentiellement sur les Alpes Maritimes et dans la ville de Nice (déplacement de la station accéléromètrique de référence du secteur Est, station en forage dans le sud de la vallée du Var). Parallèlement, les procédures de production des \"shakemap\", carte des mouvements du sol forts prédits lors d'une secousse majeure, ont été améliorées et surtout intégrées dans une chaîne plus large de récupération des données macrosismiques (BCSF, Strasbourg) et d'évaluation des dégâts potentiels (BRGM, Orléans).",
      table1: 'Porteurs du projet',
      table2: 'Partenaires',
      table3: 'Période du projet',
      table4: 'Contact Géoazur'
    }
  },
  en: {
    home: {
      eventlist1: 'Events in the study area (last 7 days)',
      eventlist2: 'Local time (Europe/Paris)',
      infoTitle: 'Further information',
      info1_1: 'The data analyzed in real time (see ',
      info1_2: ') are data from seismological stations installed by Géoazur, but also transmitted from Grenoble (ISTerre), Toulouse (OMP), and neighboring countries (Italy, Switzerland, Monaco and Spain).',
      info2_1: 'Real-time data sharing is negotiated within the framework of cross-border collaboration agreements established during the ',
      info2_2: ' project (see ',
      info2_3: ').',
      info3_1: 'The automatic detection software is ',
      info3_2: ', developed by ',
      info3_3: ', Potsdam and ',
      info3_4: '.',
      info4_1: 'Complementary tools have been developed at Géoazur by B. Delouis for automatic determination of the ',
      info4_2: 'moment magnitude',
      info4_3: ' (MWNEAR) and ',
      info4_4: 'focal mechanism',
      info4_5: ' (FMNEAR) of the most significant earthquakes.'
    },
    nav: {
      home: 'Home',
      scientificData: 'Scientific data',
      catalog: 'Catalog',
      scientificalResults: 'Scientific results',
      fdsnws: 'FDSN webservices',
      station: 'Stations',
      projects: 'Projects',
      info: 'Information',
      risk: 'LEARN MORE',
      riskDoc: 'seismic-risk_coteAzur.pdf',
      links: 'Links',
      reports: 'Specific reports'
    },
    app: {
      geolocationTitle: 'Geolocation',
      geolocationContent1: 'Activate geolocation allows you to benefit from additional tools:',
      geolocationContent2: 'list events based on your location',
      geolocationContent3: 'list stations based on your location',
      geolocationContent4: 'No personal data is communicated on the server.',
      yes: 'Yes',
      no: 'No',
      days: 'd',
      hours: 'h',
      minutes: 'm',
      seconds: 's'
    },
    event: {
      text1: 'Type',
      text2: 'Magnitude',
      text3: 'Date and time',
      text4: 'Latitude',
      text5: 'Longitude',
      text6: 'Depth',
      text7: 'Map',
      text8: 'Bulletin',
      text9: 'Stats',
      text10: 'Sismograms',
      text11: 'The seismological data distributed here is in SAC format.',
      text12: 'Authentication is required for their download.',
      text13: 'Download data',
      text14: 'Testimony',
      text15: 'Did you feel that earthquake?',
      text16: 'You can testify by filling in the ',
      text17: 'BCSF form',
      text18: ', it only takes 3 minutes!'
    },
    reports: {
      text1_1: 'The magnitude 4.1 earthquake of March 18, 2025 north of Nice, in the commune of Coaraze, and the seismic sequence that followed.',
      text1_2: 'Scientific and technical input:',
      text1_3: 'Epos-France video on the same subject:',
      text2_1: 'The magnitude 4.3 earthquake of September 14, 2024 off Cannes - Fréjus.',
      text2_2: 'Scientific and technical input:'
    },
    catalog: {
      warn1: 'The fields are set from your previous request. You can reset the fields to their default values by clicking',
      warn2: 'here',
      form1: 'Dates',
      form2: 'Last days',
      form3: 'Start',
      form4: 'End',
      form5: 'Geographic area',
      form6: 'min',
      form7: 'max',
      form8: 'Latitude',
      form9: 'Longitude',
      form10: 'Event types',
      form11: 'any',
      form12: 'earthquake',
      form13: 'quarry blast',
      form14: 'explosion',
      form15: 'undefined',
      form16: 'Depth',
      form17: 'Magnitude',
      form18: 'Output format',
      form19: 'Submit',
      form20: 'Access the event',
      form21: 'Go',
      resultNav1: 'List',
      resultNav2: 'Map',
      resultNav3: 'Stats',
      resultList1: 'Results:',
      resultList2: 'events',
      result0: 'Request parameters',
      result1: 'min',
      result2: 'max'
    },
    map: {
      legend0: 'Legend',
      legend1: 'Magnitude',
      legend2: 'Event rating',
      legend3_1: 'automatic',
      legend3_2: 'Event detected automatically, not yet confirmed by the seismological service.',
      legend4_1: 'earthquake',
      legend4_2: 'The event is an earthquake, confirmed by the seismological service.',
      legend5_1: 'artificial',
      legend5_2: 'Anthropogenic event, confirmed by the seismological service.',
      legend6_1: 'undefined',
      legend7: 'See more',
      legend8: 'Disponibility of further computation',
      legend9: 'Focal mechanism',
      legend10: 'Shakemap',
      legend11: 'Other symbols',
      legend12: 'Seismological station',
      disclaimer: 'The detection is automatic, and not necessarily validated by a seismologist.'
    },
    station: {
      list1: 'Latency',
      list2: 'stations with dayplot',
      list3: 'latency',
      panel1: 'Latitude',
      panel2: 'Longitude',
      panel3: 'Altitude',
      panel4: 'Map',
      panel5: 'Last earthquakes',
      panel6: 'Installation date',
      map1: 'Legend',
      map2: 'Latency'
    },
    links: {
      text1: 'Links',
      text2: 'Information',
      text3: 'This site was built as part of the RISE and CASSAT projects under the ALCOTRA 2007-2013 program (financed by ERDF funds, CG06, DREAL PACA and CNRS), and the RISVAL project under the Alcotra 2014-2020 program (financed by ERDF funds and CNRS).',
      text4_1: 'It is maintained as part of the ',
      text4_2: 'Géoazur seismological observation service',
      text4_3: " within the Observatoire de la Côte d'Azur.",
      text5: "The seismological network on which it relies is largely made up of instruments deployed in Provence-Alpes-Côte d'Azur on behalf of the national seismological research network (RESIF project - CNRS/INSU, OCA, IPGP, Universities, CEA, BRGM, etc.).",
      text6: 'Funding is provided by the RESIF project, with regular contributions from CG06 and occasional contributions from ALCOTRA projects.',
      text7: 'Some reference sites',
      text8: "Below you'll find a selection of interesting websites for further information.",
      text9: 'Regional websites',
      text10_1: 'DIPTERIS, partner',
      text10_2: 'Recording and characterization of earthquakes in northwestern Italy.',
      text11_1: 'ICGC',
      text11_2: 'Cartographic and Geological Institute of Catalonia.',
      text12_1: 'SED',
      text12_2: 'National center in charge of seismicity in Switzerland, Northern Alps.',
      text13_1: 'Sismalp',
      text13_2: 'Recording and analysis of seismicity in the French Alps.',
      text14_1: 'OMP',
      text14_2: 'Observatoire Midi-Pyrénées',
      text15: 'National and international websites',
      text16_1: 'EduMed Observatory',
      text16_2: 'Seismic monitoring by and for secondary school students.',
      text16_3: 'Also presents a number of educational activities based on seismological recordings.',
      text17_1: 'LDG',
      text17_2: 'Official alert bulletin for France.',
      text18_1: 'CSEM-EMSC, Euro-Mediterranean Seismological Centre ',
      text18_2: 'Focused on the Mediterranean basin, this site also monitors global seismicity.',
      text19_1: 'USGS',
      text19_2: 'Global seismic monitoring.',
      text20: 'Complementary websites',
      text21_1: 'Azurséisme',
      text21_2: 'Information about seismicity in the region.',
      text22_1: 'BCSF',
      text22_2: 'French Central Seismological Bureau'
    }
  }
}
